import premium from "../../assets/images/memberships/premium.jpg";
import List from "../../components/memberships/CheckList";
import { cartServices } from "../../services/api/cart";
import { MEMBERSHIPS_DICTIONARY } from "../../utils/MembershipsDictionary";
import { useContext, useState } from "react";
import UserContext from "../../context/UserContext";
import { notifications } from "../../utils/notifications";
import { useNavigate } from "react-router-dom";
import { memberships } from "../../utils/memberships";

const url = window.location.origin;

const elements = [
  {
    label: "Acceso a todos los proveedores de la",
    link: url + "/tienda",
  },

  {
    label: "Más de 1000 productos diferentes para envío inmediato.",
  },
  {
    label: "Garantia por defecto de fabrica en productos.",
  },
  {
    label: "Envíos express mismo dia dentro de nuestros horarios hábiles",
  },
  {
    label:
      "Servicio de logística para envíos de ventas en Mercado libre, Amazon, Shopify, redes sociales, etc.",
  },
  {
    label: "Empaque del envío (cajas, cintas, burbuja, etc)",
  },
  {
    label:
      "Acceso a toda la plataforma educativa sin costo adicional.",
  },
  {
    label: "Certificados avalados por Be Global Pro al finalizar cada curso.",
  },
  {
    label: "Capacitaciones en vivo por zoom todas las semanas para mentorías con nuestros educadores.",
  },
  {
    label: "Acompañamiento por whatsapp con nuestros expertos.",
  },
  {
    label: "Acceso para poder hacer dropshipping con el proveedor de productos americanos originales.",
  },
  {
    label: "Acceso a grupos de whatsapp para hacer compras mayoreo en tiendas de USA con una comisión muy baja de solo 10%",
  },
  { label: 'Acceso a compras personalizadas 1 a 1 en USA con una comisión del 10%' },
  { label: 'Dirección en USA para que hagas compras en sitios web por un costo mensual de solo $1,000 mxn' },
  { label: 'Logistica para importar productos desde USA y CHINA, nosotros nos encargamos de llevarlo a México' },
  { label: 'Proximidad con los CEOs' },
  { label: 'Accesos a la convención del año de BE GLOBAL PRO' },
];


const InfiniteMembership = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddMembership = async () => {
    setIsLoading(true);
    if (currentUser) {
      await cartServices
        .addMembershipToCart({
          uuid: MEMBERSHIPS_DICTIONARY[memberships.INFINITE].UUID,
          type: "Membresia",
        })
        .then((response) => {
          notifications.success(response.data.message);
          navigate("/carrito-de-compras");
        })
        .catch((err) => {
          notifications.error(err.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      notifications.warn(
        <div>
          {" "}
          <a
            onClick={() => navigate({ pathname: "", search: "?login=true" })}
            className="text-primary-200"
          >
            Inicie sesión{" "}
          </a>
          para adquirir una membresía
        </div>
      );
    }
    setIsLoading(false);
  };
  return (
    <div className="bg-white ">
      <div className="container mx-auto px-4 md:px-0  flex flex-col lg:flex-row text-xl lg:text-4xl  ">
        <div className="text-neutral-50 text-right	 w-full lg:w-2/12 flex-auto mb-10	lg:mb-20 ">
          <img
            alt=""
            src={premium}
            className="mx-auto object-cover   w-full lg:w-auto lg:object-fill h-60  lg:h-[690px]"
          ></img>
        </div>
        <div className="w-full lg:w-4/6  text-gray-700 flex-auto	 text-center lg:text-left lg:pl-8">
          <div className="mb-5">
            <h1 className="font-bold text-center lg:text-left mb-5 text-2xl lg:text-4xl">
              Franquicia Infinita
            </h1>
            <p className="font-bold text-primary-200">$38,000 MXN</p>
            <p className="text-xl lg:text-2xl text-gray-650 font-bold">
              *Unico pagó*
            </p>
          </div>

          <div className="">
            <div className="text-xl lg:text-2xl text-gray-650">
              <p className="mb-4 font-bold lg:text-3xl">
                Incluye:
              </p>
              <List elements={elements}></List>
            </div>
          </div>

          <div className="font-bold">
            <button
              disabled={isLoading}
              onClick={() => handleAddMembership()}
              type="submit"
              className="bg-primary-200 text-white text-xl lg:text-2xl py-3 px-4 disabled:opacity-75 mt-5 w-full md:w-1/3 text-center"
            >
              Adquirirla
            </button>
          </div>
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default InfiniteMembership;
