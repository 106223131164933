import basic from "../../assets/images/memberships/plus.png";
import PlusLayout from "../../components/memberships/PlusLayout";
import { useState, React, useContext, useEffect } from "react";
import { cartServices } from "../../services/api/cart";
import UserContext from "../../context/UserContext";
import { notifications } from "../../utils/notifications";
import { useNavigate } from "react-router-dom";
import { memberships } from "../../utils/memberships";
import { MEMBERSHIPS_DICTIONARY } from "../../utils/MembershipsDictionary";

const url = window.location.origin;

const elementsArr = [
  {
    label: "Acceso a todos los proveedores de la",
    link: url + "/tienda",
  },

  {
    label: "Más de 1000 productos diferentes para envío inmediato.",
  },
  {
    label: "Garantia por defecto de fabrica en productos.",
  },
  {
    label: "Envíos express mismo dia dentro de nuestros horarios hábiles",
  },
  {
    label:
      "Servicio de logística para envíos de ventas en Mercado libre, Amazon, Shopify, redes sociales, etc.",
  },
  {
    label: "Empaque del envío (cajas, cintas, burbuja, etc)",
  },
  {
    label:
      "Acceso a toda la plataforma educativa sin costo adicional.",
  },
  {
    label: "Certificados avalados por Be Global Pro al finalizar cada curso.",
  },
  {
    label: "Capacitaciones en vivo por zoom todas las semanas para mentorías con nuestros educadores.",
  },
  {
    label: "Acompañamiento por whatsapp con nuestros expertos.",
  },
  {
    label: "Acceso para poder hacer dropshipping con el proveedor de productos americanos originales.",
  },
];

const plusAnualExtrasArr = [
  {
    label: "Acceso a grupos de whatsapp para hacer compras mayoreo en tiendas de USA con una comisión muy baja de solo 10%",
  },
  { label: 'Acceso a compras personalizadas 1 a 1 en USA con una comisión del 10%' },
  { label: 'Dirección en USA para que hagas compras en sitios web por un costo mensual de solo $1,000 mxn' },
  { label: 'Logistica para importar productos desde USA y CHINA, nosotros nos encargamos de llevarlo a México' },
  { label: 'Proximidad con los CEOs' },
  { label: 'Accesos a la convención del año de BE GLOBAL PRO' },
]

const PlusMembership = () => {
  const [option, setOption] = useState(memberships.PLUS_MENSUAL);
  const [elements, setElements] = useState(elementsArr);
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  
  const handleAddMembership = async () => {
    setIsLoading(true);
    if (currentUser) {
      await cartServices
        .addMembershipToCart({
          uuid: MEMBERSHIPS_DICTIONARY[option].UUID,
          type: "Membresia",
        })
        .then((response) => {
          notifications.success(response.data.message);
          navigate("/carrito-de-compras");
        })
        .catch((err) => {
          notifications.error(err.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      notifications.warn(
        <div>
          {" "}
          <a
            onClick={() => navigate({ pathname: "", search: "?login=true" })}
            className="text-primary-200"
          >
            Inicie sesión{" "}
          </a>
          para adquirir una membresía
        </div>
      );
    }
    setIsLoading(false);
  };

  function SwitchCase(option) {
    switch (option) {
      case memberships.PLUS_TRIMESTRAL:
        return (
          <PlusLayout
            elements={elements}
            price={"Pago inicial $7,500 MXN te cubre 3 meses."}
            description={"Mensualidad $2,500 MXN."}
          ></PlusLayout>
        );
      case memberships.PLUS_SEMESTRAL:
        return (
          <PlusLayout
            elements={elements}
            price={"Pago inicial $12,000 MXN te cubre 6 meses."}
            description={"Mensualidad $2,000 MXN."}
          ></PlusLayout>
        );
      case memberships.PLUS_ANUAL:
        return (
          <PlusLayout
            elements={elements}
            price={"Pago inicial $18,000 MXN te cubre 12 meses."}
            description={"Mensualidad de $1,800 MXN."}
          ></PlusLayout>
        );
      default:
        return (
          <PlusLayout
            elements={elements}
            price={"$3,500 MXN"}
            description={"Mensualidad $3,500 MXN."}
          ></PlusLayout>
        );
    }
  }

  useEffect(() => {
    if (option === 5) {
      setElements([...elementsArr, ...plusAnualExtrasArr])
    } else {
      setElements(elementsArr)
    }
  }, [option])
  return (
    <div className="bg-white">
      <div className="container mx-auto flex flex-col lg:flex-row text-4xl">
        <div className=" text-neutral-50 text-right	w-full lg:w-2/12 flex-auto mb-10	lg:mb-20">
          <img
            alt=""
            src={basic}
            className="object-cover  w-full lg:w-auto lg:object-none h-60  lg:h-auto"
          ></img>
        </div>
        <div className="w-full lg:w-4/6 flex-auto text-gray-700 text-center lg:text-left lg:pl-8">
          <div className="mb-5">
            <h1 className="font-bold text-center lg:text-left lg:mb-5 text-2xl lg:text-4xl">
              Franquicia PLUS
            </h1>
            <div className="flex items-center lg:space-x-12 space-y-2 lg:space-y-0 text-xl lg:text-3xl mb-8">
              <div
                className={`w-full lg:w-32  cursor-pointer  ${option === memberships.PLUS_MENSUAL
                  ? "font-bold text-primary-200 underline  underline-offset-8"
                  : " "
                  } `}
                onClick={() => {
                  setOption(memberships.PLUS_MENSUAL);
                }}
              >
                Mensual
              </div>
              <div
                className={`w-full lg:w-32  cursor-pointer  ${option === memberships.PLUS_TRIMESTRAL
                  ? "font-bold text-primary-200 underline  underline-offset-8"
                  : " "
                  } `}
                onClick={() => {
                  setOption(memberships.PLUS_TRIMESTRAL);
                }}
              >
                Trimestral
              </div>
              <div
                className={`w-full lg:w-32  cursor-pointer  ${option === memberships.PLUS_SEMESTRAL
                  ? "font-bold text-primary-200 underline  underline-offset-8"
                  : " "
                  } `}
                onClick={() => {
                  setOption(memberships.PLUS_SEMESTRAL);
                }}
              >
                Semestral
              </div>
              <div
                className={`w-full lg:w-32 cursor-pointer  ${option === memberships.PLUS_ANUAL
                  ? "font-bold text-primary-200 underline  underline-offset-8"
                  : " "
                  } `}
                onClick={() => {
                  setOption(memberships.PLUS_ANUAL);
                }}
              >
                Anual
              </div>
            </div>
            {SwitchCase(option)}

            <div className="font-bold">
              <button
                disabled={isLoading}
                onClick={() => handleAddMembership()}
                type="submit"
                className="bg-primary-200 text-white text-xl lg:text-2xl py-3 px-4 disabled:opacity-75 mt-5 w-full md:w-auto text-center"
              >
                Adquirirla
              </button>
            </div>
          </div>

          <br></br>
        </div>
      </div>
    </div>
  );
};

export default PlusMembership;
