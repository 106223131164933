import { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import BasicLayout from "./layouts/BasicLayout";
import PrivateRoutes from "./utils/PrivateRoutes";
import OnlyAdminRoutes from "./utils/OnlyAdminRoutes";
import OnlyAdminRevisorRoutes from "./utils/OnlyAdminRevisorRoutes";
import OnlyAdminVentasRoutes from "./utils/OnlyAdminVentasRoutes";
import Courses from "./pages/Courses";
import Course from "./pages/Course";
import Home from "./pages/Home";
import HomeCalendar from "./pages/HomeCalendar";

import MemberForm from "./pages/MemberForm";
import Policies from "./pages/Policies";
import Conditions from "./pages/Conditions";

// import Services from "./pages/Services";

import CourseContent from "./pages/CourseContent";

import ProfileLayout from "./layouts/ProfileLayout";
import General from "./pages/profile/General";
import Billing from "./pages/profile/Billing";
import Diplomas from "./pages/profile/Diplomas";
import AddressesIndex from "./pages/profile/addresses/Index";
import AddressesNew from "./pages/profile/addresses/NewAddress";
import AddressesEdit from "./pages/profile/addresses/EditAddress";

import DesktopLayout from "./layouts/DesktopLayout";
import DesktopIndex from "./pages/desktop/Index";
import MyCourses from "./pages/desktop/MyCourses";
import Calendar from "./pages/desktop/Calendar";
import Statistics from "./pages/desktop/Statistics";
import Favorites from "./pages/desktop/Favorites";
import WishList from "./pages/desktop/WishList";

import SalesLayout from "./layouts/SalesLayout";
import SalesProducts from "./pages/desktop/sales/productsSales";
import SalesMemberships from "./pages/desktop/sales/membershipsSales";

import Orders from "./pages/desktop/Orders";
import OrderNotFound from "./pages/desktop/OrderNotFound";
import OrderDetail from "./pages/desktop/OrderDetail";

import WebDesign from "./pages/services/WebDesign";
import GraphicDesing from "./pages/services/GraphicDesign";
import ContentStrategy from "./pages/services/ContentStrategy";
import Publicity from "./pages/services/Publicity";
import Shopify from "./pages/services/Shopify";
import Community from "./pages/services/Community";
import LogoCreator from "./pages/services/LogoCreator";

import StoreLayout from "./layouts/StoreLayout";
import StoreIndex from "./pages/store/Index";
import StoreProductSingleView from "./pages/store/ProductSingleView";
import StoreCategoryView from "./pages/store/CategoryView";

import MembershipsLayout from "./layouts/MembershipsLayout";
// import BasicMembership from "./pages/plans/basic";
// import DropshippingMembership from "./pages/plans/dropshipping";
import PlusMembership from "./pages/plans/plus";
// import PremiumMembership from "./pages/plans/premium";
// import EntrepreneurMembership from "./pages/plans/entrepreneur";
import InfiniteMembership from "./pages/plans/infinite";

import ShoppingCart from "./pages/ShoppingCart";
import Payment from "./pages/Payment";
import PayData from "./pages/PayDataOr";

import AcceptPay from "./pages/AcceptPay";
import ConfirmPay from "./pages/ConfirmPay";
import DeclinePay from "./pages/DeclinePay";

import AdminLayout from "./layouts/AdminLayout";
import AdminIndex from "./pages/admin/Index";
import AdminProfileIndex from "./pages/admin/profile/Index";
import AdminChangePassword from "./pages/admin/profile/ChangePassword";

import AdminUsersAdministratorIndex from "./pages/admin/usersAdministrator/Index";
import AdminUsersAdministratorNew from "./pages/admin/usersAdministrator/NewUser";
import AdminUsersAdministratorEdit from "./pages/admin/usersAdministrator/EditUser";

import AdminUsersIndex from "./pages/admin/users/Index";
import AdminUsersIndexProduct from "./pages/admin/users/IndexProducts";

import AdminUsersMembershipsSales from "./pages/admin/users/MembershipsSales";
import AdminUsersProductsSales from "./pages/admin/users/ProductsSales";
import AdminUsersMembershipsSalesTop from "./pages/admin/users/SalesTop";
import AdminAdmissionsIndex from "./pages/admin/admissions/Index";
import AdminAdmissionsApplication from "./pages/admin/admissions/Application";
import AdminWithdrawalsIndex from "./pages/admin/withdrawals/Index";

import AdminCoursesIndex from "./pages/admin/courses/Index";
import AdminCoursesNew from "./pages/admin/courses/NewCourse";
import AdminCoursesEdit from "./pages/admin/courses/EditCourse";
import AdminCoursesNewResource from "./pages/admin/courses/resources/NewResource";
import AdminCoursesEditResource from "./pages/admin/courses/resources/EditResource";
import AdminCoursesNewSection from "./pages/admin/courses/sections/NewSection";
import AdminCoursesEditSection from "./pages/admin/courses/sections/EditSection";
import AdminCoursesNewLesson from "./pages/admin/courses/lessons/NewLesson";
import AdminCoursesEditLesson from "./pages/admin/courses/lessons/EditLesson";
import AdminCoursesQuestions from "./pages/admin/courses/Questions";
import AdminCoursesComments from "./pages/admin/courses/Comments";
import AdminIndexStudentCourse from "./pages/admin/courses/students/Index";
import AdminStudentCourse from "./pages/admin/courses/students/Student";

import AdminCalendar from "./pages/admin/Calendar";
import AdminNews from "./pages/admin/News";

import AdminProductsIndex from "./pages/admin/products/Index";
import AdminProductsNew from "./pages/admin/products/NewProduct";
import AdminProductsEdit from "./pages/admin/products/EditProduct";

import AdminLocationsEdit from "./pages/admin/locations/EditLocation";

import AdminShippingsIndex from "./pages/admin/shippings/Index";
import AdminShippingsNew from "./pages/admin/shippings/NewShipping";
import AdminShippingsEdit from "./pages/admin/shippings/EditShipping";

import AdminOrdersIndex from "./pages/admin/orders/Index";
import AdminOrdersDetails from "./pages/admin/orders/Details";
import AdminOrdersBGPIndex from "./pages/admin/orders/IndexBGP";
import AdminOrdersBGPDetails from "./pages/admin/orders/DetailsBGP";

import AdminBanners from "./pages/admin/banners/Index";
import AdminLogin from "./pages/admin/Login";

import AdminCouponsIndex from "./pages/admin/coupons/Index";
import AdminCouponsNew from "./pages/admin/coupons/NewCoupon";
import AdminCouponsEdit from "./pages/admin/coupons/EditCoupon";

import AdminIncomesIndex from "./pages/admin/contability/Index";
import AdminNewIncome from "./pages/admin/contability/NewIncome";
import AdminEditIncome from "./pages/admin/contability/EditIncome";
import AdminNewExpense from "./pages/admin/contability/NewExpense";
import AdminEditExpense from "./pages/admin/contability/EditExpense";

import AdminShopifyStores from "./pages/admin/shopifyStores/Index";
import AdminNewStore from "./pages/admin/shopifyStores/NewStore";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { UserContextProvider } from "./context/UserContext";
import { AdminUserContextProvider } from "./context/AdminUserContext";

import AdminUsersMembershipsIndex from "./pages/admin/usersMemberships/Index";
import AdminUsersEdit from "./pages/admin/usersMemberships/EditUser";
import AdminUsersCoursesStatistics from "./pages/admin/usersMemberships/UserCoursesStatistics";

import NotFoundPage from "./pages/NotFoundPage";
import AdminNotFoundPage from "./pages/admin/NotFoundPage";
import AllPosts from "./pages/blog/AllPosts";
import Post from "./pages/blog/Post";
import PostByTag from "./pages/blog/PostsByTag";
import PostByAuthor from "./pages/blog/PostByAuthor";
import PostById from "./pages/blog/PostById";
import GoogleAnalytics from "./GoogleAnalytics";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {/* APP ROUTES */}
      <UserContextProvider>
        <GoogleAnalytics />
        <Routes>
          <Route path="/" element={<BasicLayout />}>
            <Route index element={<Home />} />
            <Route path="/calendario" element={<HomeCalendar />} />
            {!window.location.pathname.includes("admin") && (
              <Route path="*" element={<NotFoundPage />} />
            )}
          </Route>

          <Route path="membresias/formulario" element={<BasicLayout />}>
            <Route index element={<MemberForm />} />
          </Route>

          <Route path="/politicas" element={<BasicLayout />}>
            <Route index element={<Policies />} />
          </Route>

          <Route path="/blog" element={<BasicLayout />}>
            <Route index element={<AllPosts />} />
            <Route path=":slug" element={<Post />} />
            <Route path="preview/:id" element={<PostById />} />
            <Route path="tag/:tag" element={<PostByTag />} />
            <Route path="autor/:slug" element={<PostByAuthor />} />
          </Route>

          <Route path="/terminos-y-condiciones" element={<BasicLayout />}>
            <Route index element={<Conditions />} />
          </Route>
          {/* 
          <Route path="/servicios" element={<BasicLayout />}>
            <Route index element={<Services />} />
            <Route
              path="/servicios/diseno-y-desarrollo-web-wordpress-woocommerce/"
              element={<WebDesign />}
            />
            <Route
              path="/servicios/diseno-grafico/"
              element={<GraphicDesing />}
            />
            <Route
              path="/servicios/diseno-y-desarrollo-web-shopify/"
              element={<Shopify />}
            />
            <Route
              path="/servicios/plan-de-estrategia-de-contenido"
              element={<ContentStrategy />}
            />
            <Route
              path="/servicios/creacion-de-campanas-publicitarias-con-facebook-e-instagram-ads/"
              element={<Publicity />}
            />
            <Route
              path="/servicios/community-manager/"
              element={<Community />}
            />
            <Route
              path="/servicios/creacion-de-logotipo/"
              element={<LogoCreator />}
            />
          </Route> */}

          <Route path="/mi-perfil" element={<ProfileLayout />}>
            <Route index element={<General />} />
            <Route path="/mi-perfil/facturacion" element={<Billing />} />
            <Route path="/mi-perfil/diplomas" element={<Diplomas />} />
            <Route path="/mi-perfil/direcciones">
              <Route index element={<AddressesIndex />} />
              <Route path="nueva" element={<AddressesNew />} />
              <Route path=":id/editar" element={<AddressesEdit />} />
            </Route>
          </Route>
          <Route
            path="/membresias"
            element={<MembershipsLayout basic={true} />}
          >
            <Route index element={<PlusMembership />} />
            {/* <Route index element={<BasicMembership />} /> */}
            {/* <Route path="/membresias/basica" element={<BasicMembership />} /> */}
            {/* <Route
              path="/membresias/dropshipping"
              element={<DropshippingMembership />}
            /> */}
            <Route path="/membresias/plus" element={<PlusMembership />} />
            {/* <Route path="/membresias/premium" element={<PremiumMembership />} /> */}
            {/* <Route
              path="/membresias/emprendedor"
              element={<EntrepreneurMembership />}
            /> */}
            <Route
              path="/membresias/infinita"
              element={<InfiniteMembership />}
            />

            <Route
              index
              element={<MemberForm></MemberForm>}
              path="/membresias/formulario"
            />
          </Route>

          <Route path="/mi-escritorio" element={<DesktopLayout />}>
            <Route index element={<DesktopIndex />} />
            <Route path="/mi-escritorio/mis-cursos" element={<MyCourses />} />
            <Route path="/mi-escritorio/calendario" element={<Calendar />} />
            <Route
              path="/mi-escritorio/estadisticas"
              element={<Statistics />}
            />
            <Route path="/mi-escritorio/favoritos" element={<Favorites />} />
            <Route
              path="/mi-escritorio/lista-de-deseos"
              element={<WishList />}
            />

            <Route
              path="/mi-escritorio/ventas"
              element={
                <Navigate replace to="/mi-escritorio/ventas/productos" />
              }
            />

            <Route element={<SalesLayout />}>
              <Route
                path="/mi-escritorio/ventas/productos"
                element={<SalesProducts />}
              />
              <Route
                path="/mi-escritorio/ventas/membresias"
                element={<SalesMemberships />}
              />
            </Route>

            <Route path="/mi-escritorio/ordenes" element={<Orders />} />
            <Route
              path="/mi-escritorio/orden/no-encontrada"
              element={<OrderNotFound />}
            />
            <Route path="/mi-escritorio/orden/:id" element={<OrderDetail />} />
          </Route>

          <Route path="/cursos" element={<BasicLayout />}>
            <Route index element={<Courses />} />
            <Route path=":uuid" element={<Course />} />
            <Route path="contenido/:uuid/:id" element={<CourseContent />} />
            <Route path="contenido/:uuid/" element={<CourseContent />} />
          </Route>

          <Route path="/tienda" element={<StoreLayout theme="light" />}>
            <Route index element={<StoreIndex />} />
            <Route
              path=":store_id/categoria/:id"
              element={<StoreCategoryView />}
            />
          </Route>

          <Route
            path="/tienda/:store_id/producto/:id"
            element={<StoreLayout />}
          >
            <Route index element={<StoreProductSingleView />} />
          </Route>

          <Route path="/carrito-de-compras" element={<BasicLayout />}>
            <Route index element={<ShoppingCart />} />
            <Route path="/carrito-de-compras/payment" element={<Payment />} />
            <Route path="/carrito-de-compras/pagar" element={<PayData />} />

            <Route
              path="/carrito-de-compras/pago/aceptado"
              element={<AcceptPay />}
            />
            <Route
              path="/carrito-de-compras/pago/confirmado"
              element={<ConfirmPay />}
            />
            <Route
              path="/carrito-de-compras/pago/declinado"
              element={<DeclinePay />}
            />
          </Route>
        </Routes>
      </UserContextProvider>

      {/* ADMIN ROUTES */}
      <AdminUserContextProvider>
        <Routes>
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/admin" element={<AdminLayout />}>
              <Route index element={<AdminIndex />} />
              <Route path="*" element={<AdminNotFoundPage />} />

              <Route path="mi-perfil" element={<AdminProfileIndex />} />
              <Route
                path="cambiar-password"
                element={<AdminChangePassword />}
              />

              <Route path="cursos">
                <Route index element={<AdminCoursesIndex />} />
                <Route path="nuevo" element={<AdminCoursesNew />} />
                <Route path="editar/:uuid" element={<AdminCoursesEdit />} />

                {/* RESOURCES */}
                <Route
                  path=":uuid/recurso/nuevo"
                  element={<AdminCoursesNewResource />}
                />
                <Route
                  path=":uuid/recurso/:id/editar"
                  element={<AdminCoursesEditResource />}
                />

                {/* SECTIONS */}
                <Route
                  path=":uuid/seccion/nueva"
                  element={<AdminCoursesNewSection />}
                />

                <Route
                  path=":uuid/seccion/:id/editar"
                  element={<AdminCoursesEditSection />}
                />

                {/* LESSONS */}
                <Route
                  path=":uuid/leccion/nueva"
                  element={<AdminCoursesNewLesson />}
                />

                <Route
                  path=":uuid/leccion/:id/editar"
                  element={<AdminCoursesEditLesson />}
                />
                <Route
                  path=":uuid/alumnos/"
                  element={<AdminIndexStudentCourse />}
                />
                <Route
                  path=":uuid/alumnos/:id"
                  element={<AdminStudentCourse />}
                />
                <Route path="preguntas" element={<AdminCoursesQuestions />} />
                <Route path="comentarios" element={<AdminCoursesComments />} />
              </Route>

              <Route path="productos">
                <Route index element={<AdminProductsIndex />} />
                <Route path="nuevo" element={<AdminProductsNew />} />
                <Route path=":id/editar" element={<AdminProductsEdit />} />
              </Route>

              <Route path="locacion">
                <Route index element={<AdminLocationsEdit />} />
              </Route>
              <Route path="envios">
                <Route index element={<AdminShippingsIndex />} />
                <Route path="nuevo" element={<AdminShippingsNew />} />
                <Route path=":id/editar" element={<AdminShippingsEdit />} />
              </Route>

              <Route path="ordenes">
                <Route path="bgp" element={<AdminOrdersBGPIndex />} />
                <Route path="bgp/:id" element={<AdminOrdersBGPDetails />} />
                <Route index element={<AdminOrdersIndex />} />
                <Route path=":id" element={<AdminOrdersDetails />} />
              </Route>

              <Route path="usuarios/miembros/productos">
                <Route index element={<AdminUsersIndexProduct />} />
                <Route
                  path=":id/ventas-productos"
                  element={<AdminUsersProductsSales />}
                />
              </Route>
              <Route path="calendario" element={<AdminCalendar />} />

              <Route element={<OnlyAdminRevisorRoutes />}>
                <Route path="admisiones">
                  <Route index element={<AdminAdmissionsIndex />} />
                  <Route path=":id" element={<AdminAdmissionsApplication />} />
                </Route>
              </Route>

              <Route element={<OnlyAdminVentasRoutes />}>
                <Route path="usuarios/miembros">
                  <Route index element={<AdminUsersIndex />} />
                  <Route
                    path="top-ventas"
                    element={<AdminUsersMembershipsSalesTop />}
                  />
                  <Route
                    path=":id/ventas-membresias"
                    element={<AdminUsersMembershipsSales />}
                  />
                  <Route path="admisiones">
                    <Route index element={<AdminAdmissionsIndex />} />
                    <Route
                      path=":id"
                      element={<AdminAdmissionsApplication />}
                    />
                  </Route>
                </Route>
                <Route path="usuarios">
                  <Route index element={<AdminUsersMembershipsIndex />} />
                  <Route path=":id" element={<AdminUsersEdit />} />
                  <Route
                    path="estadisticas/:id"
                    element={<AdminUsersCoursesStatistics />}
                  />
                </Route>
              </Route>

              <Route element={<OnlyAdminRoutes />}>
                <Route path="banners" element={<AdminBanners />} />
                <Route path="noticias" element={<AdminNews />} />

                <Route path="usuarios/administradores">
                  <Route index element={<AdminUsersAdministratorIndex />} />
                  <Route
                    path="nuevo"
                    element={<AdminUsersAdministratorNew />}
                  />
                  <Route
                    path=":id/editar"
                    element={<AdminUsersAdministratorEdit />}
                  />
                </Route>

                <Route path="usuarios/miembros">
                  <Route index element={<AdminUsersIndex />} />
                  <Route
                    path="top-ventas"
                    element={<AdminUsersMembershipsSalesTop />}
                  />
                  <Route
                    path=":id/ventas-membresias"
                    element={<AdminUsersMembershipsSales />}
                  />
                </Route>

                <Route path="contabilidad">
                  <Route index element={<AdminIncomesIndex />} />
                  <Route path="ingresos/nuevo" element={<AdminNewIncome />} />
                  <Route
                    path="ingresos/editar/:id"
                    element={<AdminEditIncome />}
                  />

                  <Route path="egresos/nuevo" element={<AdminNewExpense />} />
                  <Route
                    path="egresos/editar/:id"
                    element={<AdminEditExpense />}
                  />
                </Route>

                <Route path="retiros">
                  <Route index element={<AdminWithdrawalsIndex />} />
                </Route>
              </Route>

              <Route path="cupones">
                <Route index element={<AdminCouponsIndex />} />
                <Route path="nuevo" element={<AdminCouponsNew />} />
                <Route path=":id/editar" element={<AdminCouponsEdit />} />
              </Route>

              <Route path="tiendas">
                <Route index element={<AdminShopifyStores />} />
                <Route path="nueva" element={<AdminNewStore />} />
                <Route path=":id/editar" element={<AdminCouponsEdit />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </AdminUserContextProvider>
      <ToastContainer />
    </>
  );
}

export default App;
